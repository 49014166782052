import React from "react"
import { Helmet } from "react-helmet"

export default () => 
<div style={{ margin: "5rem auto", maxWidth: 600, textAlign: 'center'}}>
<Helmet>
    <title>Steffen Martinsen</title>
</Helmet>
<h1>Oh hai.</h1>
<p>My name is Steffen Martinsen.<br/>Full time nerd, coder, metalhead, and cat-lover.</p>
<p>Want to reach out? Find me on <a href="https://www.linkedin.com/in/steffenmartinsen/">LinkedIn</a></p>
</div>
